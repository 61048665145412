/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from 'react';

// react-router components
import AppBar from '@mui/material/AppBar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import {  useLocation } from 'react-router-dom';

// @material-ui core components

// Material Dashboard 2 PRO React TS components
// import MDBadge from 'components/MDBadge';
import MDBox from 'components/MDBox';
// import MDInput from 'components/MDInput';

// Material Dashboard 2 PRO React TS examples components
import {
  // setMiniSidenav,
  // setOpenConfigurator,
  setTransparentNavbar,
  useMaterialUIController,
} from 'context';
import Breadcrumbs from 'examples/Breadcrumbs';
import NotificationItem from 'examples/Items/NotificationItem';

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  // navbarIconButton,
  // navbarMobileMenu,
  navbarRow,
} from 'examples/Navbars/DashboardNavbar/styles';
import { useAppSelector } from 'app/redux/hooks';
import * as CoreStore from 'store/@core';

// Material Dashboard 2 PRO React context

// Declaring prop types for DashboardNavbar
interface Props {
  absolute?: boolean;
  light?: boolean;
  isMini?: boolean;
}

function DashboardNavbar({ absolute, light, isMini }: Props): JSX.Element {
  const [navbarType, setNavbarType] = useState<
    'fixed' | 'absolute' | 'relative' | 'static' | 'sticky'
  >();
  const [controller, dispatch] = useMaterialUIController();
  const {
    transparentNavbar,
    fixedNavbar,
    darkMode,
  } = controller;
  const route = useLocation().pathname.split('/').slice(1);
  const routeName = useLocation().pathname

  const grantedPageRouteConfigs = useAppSelector(
    CoreStore.select('grantedPageRouteConfigs'),
  );
  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType('sticky');
    } else {
      setNavbarType('static');
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar,
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener('scroll', handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  // const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  // const handleConfiguratorOpen = () =>
  //   setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event: any) => setOpenMenu(event.currentTarget);
  // const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  // const renderMenu = () => (
  //   <Menu
  //     anchorEl={openMenu}
  //     anchorReference={null}
  //     anchorOrigin={{
  //       vertical: 'bottom',
  //       horizontal: 'left',
  //     }}
  //     open={Boolean(openMenu)}
  //     onClose={handleCloseMenu}
  //     sx={{ mt: 2 }}
  //   >
  //     <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
  //     <NotificationItem
  //       icon={<Icon>podcasts</Icon>}
  //       title="Manage Podcast sessions"
  //     />
  //     <NotificationItem
  //       icon={<Icon>shopping_cart</Icon>}
  //       title="Payment successfully completed"
  //     />
  //   </Menu>
  // );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }: {
    palette: any;
    functions: any;
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
 
  const grantedPageRouteConfigsDeTail = (grantedPageRouteConfigs?.find((item) =>  item.pageRouteConfig?.path === routeName))
  return (
    <AppBar
      position={absolute ? 'absolute' : navbarType}
      color="inherit"
      sx={theme =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={navbarContainer}>
        <MDBox
        
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={theme => navbarRow(theme, { isMini })}
        >
          <Breadcrumbs

            icon="home"
            title={grantedPageRouteConfigsDeTail?.pageRouteConfig.name || ' '}
            route={route}
            light={light}
          />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={theme => navbarRow(theme, { isMini })}>
            {/* <MDBox color={light ? 'white' : 'inherit'}>
              <Link to="/authentication/sign-in/basic">
                <IconButton sx={navbarIconButton} size="small" disableRipple>
                  <Icon sx={iconsStyle}>account_circle</Icon>
                </IconButton>
              </Link>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? 'menu_open' : 'menu'}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                onClick={handleConfiguratorOpen}
              >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              {renderMenu()}
            </MDBox> */}
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Declaring default props for DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

export default DashboardNavbar;
