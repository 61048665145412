import React, { useEffect, useRef, useState } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { AppLoadingConfig, AppLoadingConfigProps } from '.';

const fadeIn = keyframes`
  from {
    pointer-events: none;
    opacity: 0;
  }

  to {
    pointer-events: all;
    opacity: 0.5;
  }
`;

const fadeOut = keyframes`
  from {
    pointer-events: all;
    opacity: 0.5;
  }

  to {
    pointer-events: none;
    opacity: 0;
  }
`;

const Overlay = styled.div<{ display: string }>`
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: #000000;
  pointer-events: none;
  opacity: 0;
  animation: ${props => (props.display === 'true' ? fadeIn : fadeOut)} 0.3s
    forwards ease-in-out;
  z-index: 9999;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function LoadingContainer({
  enableLogger = process.env.NODE_ENV === 'development' && false,
}: Partial<Pick<AppLoadingConfigProps, 'enableLogger'>>) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [loadingKeyList, setLoadingKeyList] = useState<string[]>([]);

  useEffect(() => {
    AppLoadingConfig.containerElement = containerRef.current;

    AppLoadingConfig.enableLogger = enableLogger;

    AppLoadingConfig.addLoading = (key: string) => {
      if (AppLoadingConfig.loadingKeyList.includes(key)) return;

      AppLoadingConfig.loadingKeyList = [
        ...AppLoadingConfig.loadingKeyList,
        key,
      ];

      setLoadingKeyList(AppLoadingConfig.loadingKeyList);

      // Logger
      if (AppLoadingConfig.enableLogger) {
        console.groupCollapsed('Show AppLoading with key: ' + key);
        console.log(
          'Current loading key list',
          AppLoadingConfig.loadingKeyList,
        );
        console.trace();
        console.groupEnd();
      }
    };

    AppLoadingConfig.removeLoading = (key: string) => {
      if (!AppLoadingConfig.loadingKeyList.includes(key)) return;

      AppLoadingConfig.loadingKeyList = AppLoadingConfig.loadingKeyList.filter(
        k => k !== key,
      );

      setLoadingKeyList(AppLoadingConfig.loadingKeyList);

      // Logger
      if (AppLoadingConfig.enableLogger) {
        console.groupCollapsed('Hide AppLoading with key: ' + key);
        console.log(
          'Current loading key list',
          AppLoadingConfig.loadingKeyList,
        );
        console.trace();
        console.groupEnd();
      }
    };
  }, [enableLogger]);

  return (
    <Overlay ref={containerRef} display={String(loadingKeyList.length > 0)}>
      <Content>
        <CircularProgress />
      </Content>
    </Overlay>
  );
}
