import _ from 'lodash';
import { joi, validateAsync } from 'app/validation';

interface CreateFetchOptions<RQM, RPM, RQD, RPD> {
  fetcher: (requestDto: RQD) => Promise<RPD>;
  requestModelValidSchema?: joi.ObjectSchema<RQM>;
  responseModelValidSchema?: joi.ObjectSchema<RPM>;
  requestMapper?: (requestDto: RQD, requestModel: Readonly<RQM>) => void;
  responseMapper?: (responseModel: RPM, responseDto: Readonly<RPD>) => void;
}

export function createFetch<RQM, RPM, RQD = RQM, RPD = RPM>(
  options: CreateFetchOptions<RQM, RPM, RQD, RPD>,
) {
  return async function fetch(requestModel: RQM) {
    try {
      // validate request model
      if (options?.requestModelValidSchema)
        await validateAsync(options.requestModelValidSchema, requestModel);

      // map request model -> request dto
      const requestDto = _.clone(requestModel) as unknown as RQD;
      if (options?.requestMapper) {
        options.requestMapper(requestDto, requestModel);
      }

      // fetch
      const responseDto = await options.fetcher(requestDto);

      // map response dto -> response model
      const responseModel = _.clone(responseDto) as unknown as RPM;
      if (options?.responseMapper)
        options.responseMapper(responseModel, responseDto);

      // validate response model
      if (options?.responseModelValidSchema)
        await validateAsync(options.responseModelValidSchema, responseModel);

      return responseModel;
    } catch (error) {
      /* ValidationError or AppHttpRequestError */
      throw _.get(error, 'message', _.toString(error));
    }
  };
}

export function createFetchNoRequestModel<RPM, RPD = RPM>(
  options: CreateFetchOptions<unknown, unknown, RPD, RPM>,
) {
  return async function fetch() {
    return createFetch(options)({});
  };
}
