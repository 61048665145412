/* eslint-disable react-hooks/exhaustive-deps */
import { useAttachLoadingReduxWithState } from 'app/loading/hooks';
import { useAppSelector } from 'app/redux/hooks';
import brandDark from 'assets/images/logo-ct-dark.png';
import brandWhite from 'assets/images/logo-ct.png';
import MDBox from 'components/MDBox';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Sidenav from 'examples/Sidenav';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import * as CoreStore from 'store/@core';
import Configurator from 'examples/Configurator';
import { useMaterialUIController } from 'context';
import MDAvatar from 'components/MDAvatar';
import profilePicture from 'assets/images/team-3.jpg';

export default function Layout2() {
  useAttachLoadingReduxWithState(CoreStore.select('logoutState'));

  const [controller] = useMaterialUIController();
  const { sidenavColor, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  // Formated route
  const grantedPageRouteConfigs = useAppSelector(
    CoreStore.select('grantedPageRouteConfigs'),
  );
  const [grantedPageRouteConfigsWithLayout] = useMemo(() => {
    const withLayout: GrantedPageRouteConfigProps[] = [];
    const withoutLayout: GrantedPageRouteConfigProps[] = [];
    for (const config of grantedPageRouteConfigs) {
      if (config.pageRouteConfig.disableLayout) withoutLayout.push(config);
      else withLayout.push(config);
    }
    return [withLayout, withoutLayout];
  }, [grantedPageRouteConfigs]);
  const formatedRoute = useMemo(() => {
    return [
      {
        type: 'collapse',
        name: 'User',
        key: 'user',
        icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
        collapse: [
          {
            name: 'Logout',
            key: 'logout',
            onSelect: () => {
              // reduxDispatch(CoreStore.actions.logoutRequest());
            },
          },
        ],
      },
      { type: 'divider', key: 'divider-0' },
      ...grantedPageRouteConfigsWithLayout.map(r => ({
        type: 'collapse',
        name: r.pageRouteConfig.name,
        key: r.pageRouteConfig.key,
        route: r.pageRouteConfig.path,
        icon: r.pageRouteConfig.icon,
        noCollapse: true,
      })),
    ];
  }, [grantedPageRouteConfigsWithLayout]);
  return (
    <>
      <Sidenav
        color={sidenavColor}
        brand={
          (transparentSidenav && !darkMode) || whiteSidenav
            ? brandDark
            : brandWhite
        }
        routes={formatedRoute}
      />
      <Configurator />
      {/* {configsButton} */}
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={1} mb={3}>
          <Outlet />
        </MDBox>
      </DashboardLayout>
    </>
  );
}
