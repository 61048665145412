import { appHttpRequest } from 'app/network/app-http-request';
import { createFetch } from 'app/service/tools';
import { joi } from 'app/validation';

//#region Request
export interface RequestModel {
  username: string;
  password: string;
}
const requestModelValidSchema = joi.object<RequestModel>({
  username: joi.string().required(),
  password: joi.string().required(),
});
type RequestDto = RequestModel;
//#endregion

//#region Response
export interface ResponseModel {
  id: number;
  username: string;
  firstName: string;
  email: string;
  lastName: string;
  token: string;
  permissions: PermissionType[];
}
const responseDtoValidSchema = joi
  .object<ResponseModel>({
    id: joi.number().required(),
    username: joi.string().required(),
    firstName: joi.string().required(),
    lastName: joi.string().required(),
    email: joi.string().required(),
    token: joi.string().required(),
    permissions: joi.array().required(),
  })
  .unknown();
type ResponseDto = ResponseModel;
//#endregion

export const fetch = createFetch<
  RequestModel,
  ResponseModel,
  RequestDto,
  ResponseDto
>({
  fetcher: requestDto => appHttpRequest.post('/auth/login', requestDto),
  requestModelValidSchema: requestModelValidSchema,
  responseModelValidSchema: responseDtoValidSchema,
});
