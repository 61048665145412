/* eslint-disable @typescript-eslint/no-unused-vars */
import { PropsWithChildren, useEffect, useState } from 'react';
import { AppContext, defaultValue } from './';

export default function AppContextProvider({
  children,
}: PropsWithChildren<unknown>) {
  const [value, setValue] = useState<AppContextProps>(defaultValue);
  useEffect(() => {
    // value.selectTheme = (theme: Theme) => {
    //   setValue({ ...value, selectedTheme: theme });
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
