import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppContextProvider from 'app/context/AppContextProvider';
import App from 'app/index';
// import App from "App";
import LoadingContainer from 'app/loading/LoadingContainer';
import NotificationContainer from 'app/notification/NotificationContainer';
import AppReduxProvider from 'app/redux/AppReduxProvider';
import { MaterialUIControllerProvider } from 'context';
import 'styles/global.styles.css';
const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppReduxProvider>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
        <NotificationContainer />
        <LoadingContainer />
      </AppReduxProvider>
    </AppContextProvider>
  </BrowserRouter>,
);
