import moment from 'moment';

export function remove(key: string) {
  localStorage.removeItem(key);
}

export function exist(key: string) {
  return !!localStorage.getItem(key);
}

export function count() {
  return localStorage.length;
}

export function clear() {
  localStorage.clear();
}

export function setString(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function getString(key: string, defaultValue?: string) {
  return localStorage.getItem(key) || defaultValue;
}

export function setObject<T>(key: string, value: T) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function getObject<T>(key: string, defaultValue: T) {
  try {
    const value = localStorage.getItem(key);
    if (!value) return defaultValue;
    return JSON.parse(value) as T;
  } catch (error) {
    console.error(error);
    return defaultValue;
  }
}

export enum OrderTransactionStatus {
  NEW = 'NEW',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum OrderStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REFUND = 'REFUND',
}

export enum BillStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  CANCELLED = 'CANCELLED',
  UNDEFINED = 'UNDEFINED',
}

export interface BillStatusMetaType {
  status: BillStatus;
  name: string;
  color: string;
}

export const BillStatusList: BillStatusMetaType[] = [
  {
    status: BillStatus.PAID,
    name: 'Đăng ký thành công',
    color: 'success',
  },
  {
    status: BillStatus.PENDING,
    name: 'Chờ thanh toán phí đăng ký',
    color: 'pending',
  },
  {
    status: BillStatus.CANCELLED,
    name: 'Đã huỷ',
    color: 'fail',
  },
  {
    status: BillStatus.CANCELLED,
    name: 'Đã huỷ',
    color: 'fail',
  },
  {
    status: BillStatus.UNDEFINED,
    name: 'Không xác định',
    color: '',
  },
];
export function getBillStatus(
  isActive,
  orderStatus: string,
  expireTime,
  isFree,
) {
  if (!isActive) {
    return BillStatusList.find(b => b.status === BillStatus.CANCELLED);
  }
  if (
    orderStatus === OrderStatus.REFUND &&
    moment(expireTime).diff(moment(), 'seconds') > 0
  ) {
    return BillStatusList.find(b => b.status === BillStatus.PENDING);
  }

  if (!isFree) return BillStatusList.find(b => b.status === BillStatus.PAID);

  const status = BillStatusList.find(b => b.status === (orderStatus as any));
  if (
    status?.status === BillStatus.PENDING &&
    moment(expireTime).diff(moment(), 'seconds') <= 0
  )
    return undefined;
  return status || BillStatusList.find(b => b.status === 'UNDEFINED');
}
