import { ValidationError as JoiValidationError, Schema } from 'joi';
export { default as joi } from 'joi';

export class ValidationError extends Error {
  isValidationError = true;
  constructor(message?: string) {
    super(message);
    this.name = this.constructor.name;
  }
}

export function validate<T>(
  validSchema: Schema<T>,
  value: T,
  errorTitle = 'VALIDATION',
) {
  const { error } = validSchema.validate(value);
  if (!error) return;
  console.error(error);
  const err = error as JoiValidationError;
  throw new ValidationError(`${errorTitle}: ${err.message}`);
}

export async function validateAsync<T>(
  validSchema: Schema<T>,
  value: T,
  errorTitle = 'VALIDATION',
) {
  try {
    await validSchema.validateAsync(value);
  } catch (error) {
    console.error(error);
    const err = error as JoiValidationError;
    throw new ValidationError(`${errorTitle}: ${err.message}`);
  }
}
