/* eslint-disable no-restricted-imports */
import { ToastContainer } from 'react-toastify';

export default function NotificationContainer() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      draggable
      pauseOnHover
      pauseOnFocusLoss
      theme="colored"
    />
  );
}
