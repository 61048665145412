/* eslint-disable import/first */
/* eslint-disable import/order */
import MockAdapter from 'axios-mock-adapter';
import { appHttpRequest } from 'app/network/app-http-request';

const mockAdapter = new MockAdapter(appHttpRequest.axiosInstance, {
  delayResponse: 1000,
  onNoMatch: 'passthrough',
});

// Add or remove dummies here
import * as auth from './auth';
auth.login_POST(mockAdapter);
auth.logout_POST(mockAdapter);
import * as book from './book';
book.book_POST(mockAdapter);
book.bookList_GET(mockAdapter);
book.book_GET(mockAdapter);
book.book_PUT(mockAdapter);
book.book_DELETE(mockAdapter);
