import React, { ComponentProps, ComponentType, Suspense, lazy } from 'react';
import ProgessLoading from 'common/components/ProgressLoading';

export default function lazyload<
  T extends Promise<unknown>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  U extends ComponentType<any>,
>(lazyImport: () => T, componentSelector: (module: Awaited<T>) => U) {
  const LazyComp = lazy(() =>
    lazyImport().then(module => ({
      default: componentSelector(module as Awaited<T>),
    })),
  );

  return (props: ComponentProps<U>) => (
    <Suspense fallback={<ProgessLoading />}>
      <LazyComp {...props} />
    </Suspense>
  );
}
