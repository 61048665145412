// Should not be edited code below
import slice from './slice';
import { RootState } from 'store/RootState';

type StateProps = ReturnType<typeof slice.getInitialState>;

export function select<K extends keyof StateProps>(stateName: K) {
  return (state: RootState) =>
    ((state[slice.name] || slice.getInitialState()) as StateProps)[stateName];
}
export const { name, reducer, actions } = slice;
export { default as saga } from './saga';
