import { useEffect, useRef } from 'react';
import { generateKey, hideLoading, showLoading } from '.';
import { useAppSelector } from 'app/redux/hooks';
import { RootState } from 'store/RootState';

export function useAttachLoading(loading?: boolean, key?: string) {
  const keyRef = useRef<string | null>(null);
  useEffect(() => {
    if (loading) {
      if (keyRef.current === null) keyRef.current = key || generateKey();
      showLoading(keyRef.current);
    } else {
      if (keyRef.current !== null) hideLoading(keyRef.current);
    }
    return () => {
      if (keyRef.current !== null) hideLoading(keyRef.current);
    };
  }, [loading, key]);
}

export function useAttachLoadingRedux(
  selector: (state: RootState) => boolean | undefined,
) {
  const reduxLoading = useAppSelector(selector);
  useAttachLoading(reduxLoading);
  return reduxLoading;
}

export function useAttachLoadingReduxWithState(
  selector: (state: RootState) => AsyncState,
) {
  const reduxState = useAppSelector(selector);
  useAttachLoading(reduxState === 'PENDING');
  return reduxState;
}
