// import core from './@core';
// import dashboard from './dashboard';
import ExaminationBill from './examinationBill';
// import Profile from './profile';
import TranSaction from './tranSaction';
// import Account from './account';

export const modules: AppModuleProps[] = [
  // core,
  ExaminationBill,
  TranSaction,
  // Profile,
  // Account,
  // dashboard,
];
