/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import _ from 'lodash';

/**
 * Default option
 */
const defaultOption: Required<HttpRequestOptionProps> = {
  baseUrl: null,
  // Default headers
  headers: {
    'test-http-request-default-header': '*',
  },
  timeout: 30000,
};

/**
 * Error
 * @SS Server Success Response Data Props
 * @SE Server Error Response Props
 */
export class HttpRequestError<SS, SE> extends Error {
  isHttpRequestError = true;
  response: HttpResponse<SS, SE>;
  constructor(response: HttpResponse<SS, SE>, message?: string) {
    super(
      message ||
        (_.get(response?.error, 'message') as string) ||
        response.httpStatusMessage,
    );
    this.name = this.constructor.name;
    this.response = response;
  }
}

/**
 * @SE Server Error Response Props
 */
export class HttpRequest<SE> {
  axiosInstance: AxiosInstance;

  /**
   * Create an http request instance with base url
   * @param option
   */
  constructor(option?: HttpRequestOptionProps) {
    const otp: HttpRequestOptionProps = {
      ...defaultOption,
      ...option,
      headers: {
        ...defaultOption.headers,
        ...option?.headers,
      },
    };
    this.axiosInstance = axios.create({
      baseURL: otp.baseUrl || undefined,
      headers: otp.headers,
      responseType: 'json',
      timeout: otp.timeout,
    });
  }

  /**
   * Calling an http request
   * @RPD Response Data Props
   * @RQP Request Params Props
   * @RQD Request Data Props
   * @param method
   * @param url
   * @param reqData
   * @returns
   */
  async request<RPD, RQP, RQD>(
    options: AxiosRequestConfig<RQD>,
    url?: string,
    reqParams?: RQP,
    reqData?: RQD,
  ): Promise<RPD>;
  async request<RPD, RQP, RQD>(
    method: HttpRequestMethod,
    url: string,
    reqParams?: RQP,
    reqData?: RQD,
  ): Promise<RPD>;
  async request<RPD, RQP, RQD>(
    methodOrOptions: unknown,
    url?: string,
    reqParams?: RQP,
    reqData?: RQD,
  ) {
    const response: HttpResponse<RPD, SE> = {
      status: 'client_error',
    };
    try {
      let config: AxiosRequestConfig<RQD>;
      if (typeof methodOrOptions === 'string') {
        config = {
          url: encodeURI(url!),
          method: methodOrOptions as HttpRequestMethod,
          params: reqParams,
          data: reqData,
        };
      } else config = methodOrOptions as AxiosRequestConfig<RQD>;
      const success = await this.axiosInstance(config);
      response.status = 'success';
      response.httpStatusCode = success.status;
      response.httpStatusMessage = success.statusText;
      response.data = success.data as RPD;
      return response.data;
    } catch (error) {
      response.status = 'client_error';
      if ((error as AxiosError)?.isAxiosError) {
        const err = error as AxiosError;
        if (err.response?.status) {
          response.status = 'server_error';
          response.httpStatusCode = err.response.status;
          response.httpStatusMessage = err.response.statusText || err.message;
          response.error = err.response.data as SE;
        } else {
          response.error = {
            code: err.code,
            message: err.message,
          } as ClientErrorProps;
        }
      } else {
        response.error = {
          code: _.get(error, 'code'),
          message: _.isString(error)
            ? error
            : _.get(error, 'message', 'request catch error'),
        } as ClientErrorProps;
      }
    }
    throw new HttpRequestError(response);
  }
}

/**
 * Config response for axios-mock-adapter
 */
export class MockResponseConfig<S, H, E> {
  requestConfig?: AxiosRequestConfig = undefined;
  statusCode = -1;
  data?: S | E = undefined;
  headers?: H = undefined;

  constructor(requestConfig: AxiosRequestConfig) {
    this.requestConfig = requestConfig;
  }

  reply(statusCode?: number, data?: S, headers?: H) {
    this.statusCode = statusCode || this.statusCode;
    this.data = data || this.data;
    this.headers = headers || this.headers;

    console.groupCollapsed(
      `MockAPI: ${new URL(
        this.requestConfig?.url || '',
        this.requestConfig?.baseURL,
      )}`,
    );

    console.groupEnd();
    return [this.statusCode, this.data, this.headers];
  }

  setSuccess(data?: S, headers?: H) {
    this.statusCode = 200;
    this.data = data;
    this.headers = headers || this.headers;
  }

  replySuccess(data?: S, headers?: H) {
    this.setSuccess(data, headers);
    return this.reply();
  }

  setBadRequest(data: E, headers?: H) {
    this.statusCode = 400;
    this.data = data;
    this.headers = headers || this.headers;
  }

  replyBadRequest(data: E, headers?: H) {
    this.setBadRequest(data, headers);
    return this.reply();
  }

  setNotFound(data: E, headers?: H) {
    this.statusCode = 404;
    this.data = data;
    this.headers = headers || this.headers;
  }

  replyNotFound(data: E, headers?: H) {
    this.setBadRequest(data, headers);
    return this.reply();
  }
}
