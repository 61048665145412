export interface AppLoadingConfigProps {
  containerElement: HTMLDivElement | null;
  loadingKeyList: string[];
  addLoading: (string) => void;
  removeLoading: (string) => void;
  autoId: number;
  autoIdPrefix: string;
  enableLogger: boolean;
}

export const AppLoadingConfig: AppLoadingConfigProps = {
  containerElement: null,
  loadingKeyList: [],
  addLoading: () => undefined,
  removeLoading: () => undefined,
  autoId: 0,
  autoIdPrefix: 'AUTOID_',
  enableLogger: false,
};

export function generateKey() {
  return AppLoadingConfig.autoIdPrefix + AppLoadingConfig.autoId++;
}

export function showLoading(key?: string) {
  if (AppLoadingConfig.containerElement) {
    key = key || generateKey();
    AppLoadingConfig.addLoading(key);
    return key;
  } else console.error('Not found LoadingContainer.');
}

export function hideLoading(key: string) {
  if (AppLoadingConfig.containerElement) {
    AppLoadingConfig.removeLoading(key);
  } else console.error('Not found LoadingContainer.');
}

export { useAttachLoading, useAttachLoadingRedux } from './hooks';
