/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import MockAdapter from 'axios-mock-adapter';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { AppMockResponseConfig } from 'app/network/app-http-request';
import { parseJson } from 'common/utils/json';
import * as storage from 'common/utils/persistent-storage';

// Dummy data
interface Book {
  _id: string;
  _title: string;
  _description: string;
  _author: string;
}
let dummyBooks: Book[] = storage.getObject('__DUMMY_BOOKS', []);
if (dummyBooks.length === 0) {
  for (let i = 0; i < 100; i++) {
    dummyBooks.unshift({
      _id: uuid(),
      _title: 'asddsa',
      _description: 'asd',
      _author: 'dsa',
    });
  }
  storage.setObject('__DUMMY_BOOKS', dummyBooks);
}
function dummyCreate(book: Book) {
  book._id = uuid();
  if (dummyBooks.find(b => b._id === book._id)) {
    return undefined;
  } else {
    book._id = uuid();
    dummyBooks.unshift(book);
    storage.setObject('__DUMMY_BOOKS', dummyBooks);
    return book;
  }
}
function dummyList(length: number, page: number) {
  return dummyBooks.slice(length * page, length * page + length);
}
function dummyDetail(id: string) {
  return dummyBooks.find(b => b._id === id);
}
function dummyUpdate(book: Book) {
  const b = dummyBooks.find(b => b._id === book._id);
  if (b) {
    b._title = book._title;
    b._description = book._description;
    b._author = book._author;
    storage.setObject('__DUMMY_BOOKS', dummyBooks);
    return b;
  } else {
    return undefined;
  }
}
function dummyDelete(id: string) {
  const b = dummyBooks.find(b => b._id === id);
  if (b) {
    dummyBooks = dummyBooks.filter(b => b._id !== id);
    storage.setObject('__DUMMY_BOOKS', dummyBooks);
    return b;
  } else {
    return undefined;
  }
}

export function book_POST(mockAdapter: MockAdapter) {
  mockAdapter.onPost('book').reply(requestConfig => {
    const response = new AppMockResponseConfig(requestConfig);
    const requestData = parseJson<Book>(requestConfig?.data);
    try {
      const book = dummyCreate(requestData!);
      if (book) {
        return response.replySuccess(book);
      } else {
        return response.replyBadRequest({
          message: 'Existed book',
        });
      }
    } catch (error) {
      return response.replyBadRequest({
        message: _.get(error, 'message') || '',
      });
    }
  });
}

export function bookList_GET(mockAdapter: MockAdapter) {
  mockAdapter.onGet('books').reply(requestConfig => {
    const response = new AppMockResponseConfig(requestConfig);
    const requestParams = requestConfig.params;
    try {
      const totalItems = dummyBooks.length;
      const totalPages = Math.ceil(totalItems / requestParams!.length);
      const pageIndex =
        requestParams!.page > totalPages ? totalPages - 1 : requestParams!.page;
      const list: any = {
        total: totalItems,
        length: requestParams!.length,
        page: pageIndex,
        list: dummyList(requestParams!.length, pageIndex),
      };
      return response.replySuccess(list);
    } catch (error) {
      return response.replyBadRequest({
        message: _.get(error, 'message') || '',
      });
    }
  });
}

export function book_GET(mockAdapter: MockAdapter) {
  mockAdapter.onGet('book').reply(requestConfig => {
    const response = new AppMockResponseConfig(requestConfig);
    const requestParams = requestConfig.params;
    try {
      return response.replySuccess(dummyDetail(requestParams._id));
    } catch (error) {
      return response.replyBadRequest({
        message: _.get(error, 'message') || '',
      });
    }
  });
}

export function book_PUT(mockAdapter: MockAdapter) {
  mockAdapter.onPut('book').reply(requestConfig => {
    const response = new AppMockResponseConfig(requestConfig);
    const requestData = parseJson<Book>(requestConfig?.data);
    try {
      const b = dummyUpdate(requestData!);
      if (b) {
        return response.replySuccess(b);
      } else {
        return response.replyNotFound({
          message: 'Not Found Book',
        });
      }
    } catch (error) {
      return response.replyBadRequest({
        message: _.get(error, 'message') || '',
      });
    }
  });
}

export function book_DELETE(mockAdapter: MockAdapter) {
  mockAdapter.onDelete('book').reply(requestConfig => {
    const response = new AppMockResponseConfig(requestConfig);
    const requestData = parseJson<Book>(requestConfig?.data);
    try {
      const book = dummyDelete(requestData!._id);
      if (book) {
        return response.replySuccess(book);
      } else {
        return response.replyNotFound({
          message: 'Not Found Book',
        });
      }
    } catch (error) {
      return response.replyBadRequest({
        message: _.get(error, 'message') || '',
      });
    }
  });
}
