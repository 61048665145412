import { createModule } from 'app/index';
import lazyload from 'app/router/lazyload';

const TranSaction = lazyload(
  () => import('./pages/TranSaction'),
  module => module.default,
);

export default createModule({
  route: {
    key: 'TranSaction',
    path: '/TranSaction',
    name: 'Quản lý giao dịch',
    Component: TranSaction,
    allowAnonymous: false,
    permissions: ['BOOK_CREATE', 'BOOK_READ', 'BOOK_UPDATE', 'BOOK_DELETE'],
    permissionsCondition: 'ANY',
    icon: 'update',
  },
});
