/* eslint-disable no-restricted-imports */
import { ToastOptions, toast } from 'react-toastify';

export function notify(
  type: 'SUCCESS' | 'ERROR' | 'WARNING' | 'INFO',
  content: string,
) {
  const otp: ToastOptions = {};
  switch (type) {
    case 'SUCCESS':
      otp.type = 'success';
      otp.autoClose = 2000;
      break;
    case 'ERROR':
      otp.type = 'error';
      otp.autoClose = 3000;
      break;
    case 'WARNING':
      otp.type = 'warning';
      otp.autoClose = 3000;
      break;
    case 'INFO':
      otp.type = 'info';
      otp.autoClose = 3000;
      break;
    default:
      otp.type = 'default';
      otp.autoClose = 3000;
      break;
  }
  toast(content, otp);
}
