import { useEffect } from 'react';
import nprogress from 'nprogress';

export default function ProgressLoading() {
  useEffect(() => {
    nprogress.start();
    return () => {
      nprogress.done();
    };
  }, []);
  return null;
}
