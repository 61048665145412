import {
  HttpRequest,
  HttpRequestError,
  MockResponseConfig,
} from './base/http-request';
import { AxiosRequestConfig } from 'axios';

//#region Pagination Props
interface PaginationPositionProps {
  numberItemsPerPage: number;
  pageIndex: number;
}
interface PaginationOptionProps {
  // Filtering and sorting
  filter?: unknown; // TODO
  sort?: unknown; // TODO
}
export type PaginationRequestProps = PaginationPositionProps &
  PaginationOptionProps;
export type PaginationResponseProps<T> = PaginationPositionProps & {
  totalItems: number;
  list: T[];
};
//#endregion

/* Config error response props of server */
export interface AppServerErrorResponseProps {
  code?: number | string | null;
  message: string;
}
/* Get error message from error response props of server */
export class AppHttpRequestError<SS = unknown> extends HttpRequestError<
  SS,
  AppServerErrorResponseProps
> {
  constructor(response: HttpResponse<SS, AppServerErrorResponseProps>) {
    super(response, response.error?.message);
  }
}

export class AppHttpRequest extends HttpRequest<AppServerErrorResponseProps> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(option?: HttpRequestOptionProps) {
    super(option);

    // Unauthorize
    // this.axiosInstance.interceptors.response.use(
    //   response => response,
    //   error => {
    //     if (error.response.status === 401) {
    //       // Logout
    //       storage.clear();
    //       location.reload();
    //     } else {
    //       return Promise.reject(error);
    //     }
    //   },
    // );
  }

  /**
   * Calling an http request
   * @RPD Response Data Props
   * @RQP Request Params Props
   * @RQD Request Data Props
   * @param method
   * @param url
   * @param reqData
   * @returns
   */
  async request<RPD, RQP, RQD>(
    options: AxiosRequestConfig<RQD>,
    url?: string,
    reqParams?: RQP,
    reqData?: RQD,
    disabledFormated?: boolean,
  ): Promise<RPD>;
  async request<RPD, RQP, RQD>(
    method: HttpRequestMethod,
    url: string,
    reqParams?: RQP,
    reqData?: RQD,
    disabledFormated?: boolean,
  ): Promise<RPD>;
  async request<RPD, RQP, RQD>(
    methodOrOptions: any,
    url?: string,
    reqParams?: RQP,
    reqData?: RQD,
    disabledFormated?: boolean,
  ) {
    try {
      const res = await super.request<RPD, RQP, RQD>(
        methodOrOptions,
        url,
        reqParams,
        reqData,
      );
      if (disabledFormated) return res;
      return res;
    } catch (error) {
      const err = error as HttpRequestError<RPD, AppServerErrorResponseProps>;
      throw new AppHttpRequestError(err.response);
    }
  }

  /**
   * @RPD Response Data Props
   * @RQP Request Params Props
   * @RQD Request Data Props
   * @param url
   * @param reqParams
   * @returns
   */
  get<RPD, RQP, RQD>(
    url: string,
    reqParams?: RQP,
    reqData?: RQD,
    disabledFormated?: boolean,
  ) {
    return this.request<RPD, RQP, RQD>(
      'GET',
      url,
      reqParams,
      reqData,
      disabledFormated,
    );
  }

  /**
   * Use for pagination
   * @RPD Response Data Props
   * @param url
   * @param reqParams
   * @returns
   */
  getPagination<RPD>(url: string, reqParams?: PaginationRequestProps) {
    return this.request<
      PaginationResponseProps<RPD>,
      PaginationRequestProps,
      unknown
    >('GET', url, reqParams);
  }

  /**
   * @RPD Response Data Props
   * @RQD Request Data Props
   * @param url
   * @param reqData
   * @returns
   */
  post<RPD, RQD, RQP>(
    url: string,
    reqData?: RQD,
    reqParams?: RQP,
    disabledFormated?: boolean,
  ) {
    return this.request<RPD, unknown, RQD>(
      'POST',
      url,
      reqParams,
      reqData,
      disabledFormated,
    );
  }

  /**
   * @RPD Response Data Props
   * @RQD Request Data Props
   * @param url
   * @param reqData
   * @returns
   */
  put<RPD, RQD, RQP>(
    url: string,
    reqData?: RQD,
    reqParams?: RQP,
    disabledFormated?: boolean,
  ) {
    return this.request<RPD, unknown, RQD>(
      'PUT',
      url,
      reqParams,
      reqData,
      disabledFormated,
    );
  }

  /**
   * @RPD Response Data Props
   * @RQD Request Data Props
   * @param url
   * @param reqData
   * @returns
   */
  patch<RPD, RQD, RQP>(
    url: string,
    reqData?: RQD,
    reqParams?: RQP,
    disabledFormated?: boolean,
  ) {
    return this.request<RPD, unknown, RQD>(
      'PATCH',
      url,
      reqParams,
      reqData,
      disabledFormated,
    );
  }

  /**
   * @RPD Response Data Props
   * @RQD Request Data Props
   * @param url
   * @param reqData
   * @returns
   */
  delete<RPD, RQD, RQP>(url: string, reqData?: RQD, reqParams?: RQP) {
    return this.request<RPD, unknown, RQD>('DELETE', url, reqParams, reqData);
  }

  setAuthorizationToken(token: string) {
    this.axiosInstance.defaults.headers['Authorization'] = token;
  }
}

export const appHttpRequest = new AppHttpRequest({
  baseUrl: process.env.REACT_APP_BASE_URL,
  headers: {
    'test-app-http-request-add-header': '*',
    // Authorization: storage.getString('__AUTH_TOKEN') || '',
  },
});

/**
 * Mock requests for testing
 */
export class AppMockResponseConfig<S, H> extends MockResponseConfig<
  S,
  H,
  AppServerErrorResponseProps
> {}
if (process.env.REACT_APP_MOCK) {
  require('test/app-http-request');
}
