import { Action, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions } from '.';
import { appHttpRequest } from 'app/network/app-http-request';
import { notify } from 'app/notification';
import * as storage from 'common/utils/persistent-storage';
import * as authLoginService from 'services/auth/login';
import * as authLogoutService from 'services/auth/logout';

/* Notification */
export function* notificationSuccessWorker(action: PayloadAction<string>) {
  yield notify('SUCCESS', _.get(action, 'payload', 'Success'));
}
export function* notificationFailureWorker(action: PayloadAction<string>) {
  yield notify('ERROR', _.get(action, 'payload', 'Failure'));
}

export function* loginWorker(
  action: PayloadAction<authLoginService.RequestModel>,
) {
  try {
    const response: authLoginService.ResponseModel = yield call(
      authLoginService.fetch,
      action.payload,
    );
    storage.setString('__AUTH_TOKEN', response.token);
    storage.setObject('__AUTH_PERMISSIONS', response.permissions);
    appHttpRequest.setAuthorizationToken(response.token);
    yield put(actions.loginSuccess(response));
  } catch (error) {
    yield put(actions.loginFailure(error as AppBaseError));
  }
}

export function* logoutWorker() {
  try {
    yield call(authLogoutService.fetch);
    storage.remove('__AUTH_TOKEN');
    storage.remove('__AUTH_PERMISSIONS');
    appHttpRequest.setAuthorizationToken('');
    yield put(actions.logoutSuccess());
  } catch (error) {
    yield put(actions.logoutFailure(error as AppBaseError));
  }
}

export default function* watcher() {
  yield all([
    /*takeEvery((action: Action) => action.type.endsWith('Success'), notificationSuccessWorker),*/
    takeEvery(
      (action: Action) => action.type.endsWith('Failure'),
      notificationFailureWorker,
    ),
    takeLatest(actions.loginRequest.type, loginWorker),
    takeLatest(actions.logoutRequest.type, logoutWorker),
  ]);
}
