import { useEffect, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import NoPermission from 'common/components/NoPermission';
import NotFound from 'common/components/NotFound';
import { modules } from 'modules';
import { actions, select } from 'store/@core';

const prcs = modules.map(m => m.route);
const pageRouteConfigs = prcs.reduce<PageRouteConfigProps[]>(
  (rpc, cpc) => rpc.concat(cpc),
  [],
);

function isGranted(
  condition: PermissionsCondition,
  userPermissions: PermissionType[],
  routePermission: PermissionType[],
) {
  for (const p of routePermission) {
    if ((!condition || condition === 'ALL') && !userPermissions.includes(p))
      return false;
    else if (condition === 'ANY' && userPermissions.includes(p)) return true;
  }
  return !condition || condition === 'ALL' ? true : false;
}

function getGrantedPageRoutesConfigs(
  isAuthen: boolean,
  userPermissions: PermissionType[],
) {
  return pageRouteConfigs.map<GrantedPageRouteConfigProps>(config => {
    const grantedPageRouteConfig: GrantedPageRouteConfigProps = {
      pageRouteConfig: config,
      missingRequiredAccess: 'NONE',
    };
    if (config.allowAnonymous) {
      if (config.redirectPathIfAuthenticated && isAuthen)
        grantedPageRouteConfig.missingRequiredAccess = 'GUEST';
    } else if (!isAuthen) {
      grantedPageRouteConfig.missingRequiredAccess = 'AUTHENTICATED_USER';
    } else if (
      !isGranted(
        config.permissionsCondition,
        userPermissions,
        config.permissions,
      )
    )
      grantedPageRouteConfig.missingRequiredAccess = 'AUTHORIZED_USER';
    return grantedPageRouteConfig;
  });
}

function renderPageRoutes(
  grantedPageRouteConfigs: GrantedPageRouteConfigProps[],
) {
  return grantedPageRouteConfigs.map(config => {
    const { key, path, Component } = config.pageRouteConfig;
    switch (config.missingRequiredAccess) {
      case 'GUEST':
        return (
          <Route
            key={key}
            path={path}
            element={
              <Navigate
                to={config.pageRouteConfig.redirectPathIfAuthenticated || '/'}
              />
            }
          />
        );
      // case 'AUTHENTICATED_USER':
      //   return (
      //     <Route key={key} path={path} element={<Navigate to={'/login'} />} />
      //   );
      case 'AUTHORIZED_USER':
        return <Route key={key} path={path} element={<NoPermission />} />;
      default:
        return <Route key={key} path={path} element={<Component />} />;
    }
  });
}

interface AppRoutesProps {
  Layout: React.FC;
}
export function AppRoutes({ Layout }: AppRoutesProps) {
  const dispatch = useAppDispatch();

  const isAuthenticated = useAppSelector(select('isAuthenticated'));
  const userPermissions = useAppSelector(select('userPermissions'));
  const grantedPageRouteConfigs = useAppSelector(
    select('grantedPageRouteConfigs'),
  );

  useEffect(() => {
    dispatch(
      actions.setGrantedPageRouteConfigs(
        getGrantedPageRoutesConfigs(isAuthenticated, userPermissions),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, userPermissions]);

  const [
    grantedPageRouteConfigsWithLayout,
    grantedPageRouteConfigsWithoutLayout,
  ] = useMemo(() => {
    const withLayout: GrantedPageRouteConfigProps[] = [];
    const withoutLayout: GrantedPageRouteConfigProps[] = [];
    for (const config of grantedPageRouteConfigs) {
      if (config.pageRouteConfig.disableLayout) withoutLayout.push(config);
      else withLayout.push(config);
    }
    return [withLayout, withoutLayout];
  }, [grantedPageRouteConfigs]);

  return (
    <Routes>
      <Route element={<Layout />}>
        {renderPageRoutes(grantedPageRouteConfigsWithLayout)}
        {isAuthenticated && <Route path="*" element={<NotFound />} />}
      </Route>
      {renderPageRoutes(grantedPageRouteConfigsWithoutLayout)}
      {!isAuthenticated && (
        <Route
          path="*"
          element={
            <Navigate to={'/examinationBill'} />
            /*<NotFound />*/
          }
        />
      )}
    </Routes>
  );
}
