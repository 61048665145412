import { AppRoutes } from './router';
import theme from 'assets/theme';
import themeDark from 'assets/theme-dark';
import { useMaterialUIController } from 'context';
import Layout from 'modules/@core/containers/Layout';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { AppThemeProvider } from 'components/ui/theme';
export function createModule(options: AppModuleProps) {
  return options;
}

export default function App() {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <AppThemeProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <AppRoutes Layout={Layout} />
        </LocalizationProvider>
      </AppThemeProvider>
    </ThemeProvider>
  );
}
