import React, { PropsWithChildren } from 'react';
import 'styles/global.styles.css';
import { Provider } from 'react-redux';
import { store } from './';

export default function AppReduxProvider({
  children,
}: PropsWithChildren<unknown>) {
  return <Provider store={store}>{children}</Provider>;
}
