import * as storage from 'common/utils/persistent-storage';

export interface CoreStateProps extends BaseReduxStateProps {
  userPermissions: PermissionType[];
  authToken?: string;
  isAuthenticated: boolean;
  grantedPageRouteConfigs: GrantedPageRouteConfigProps[];

  loginState: AsyncState;
  loginError?: AppBaseError;
  logoutState: AsyncState;
  logoutError?: AppBaseError;
}

export const initialState: CoreStateProps = {
  userPermissions: storage.getObject<PermissionType[]>(
    '__AUTH_PERMISSIONS',
    [],
  ),
  authToken: storage.getString('__AUTH_TOKEN', undefined),
  get isAuthenticated() {
    return !!this.authToken;
  },
  grantedPageRouteConfigs: [],

  loginState: 'IDLE',
  logoutState: 'IDLE',
};
