import { createModule } from 'app/index';
import lazyload from 'app/router/lazyload';

const ExaminationBill = lazyload(
  () => import('./pages/ExaminationBill'),
  module => module.default,
);

export default createModule({
  route: {
    key: 'examinationBill',
    path: '/examinationBill',
    name: 'Quản lý phiếu khám',
    Component: ExaminationBill,
    allowAnonymous: false,
    permissions: ['BOOK_CREATE', 'BOOK_READ', 'BOOK_UPDATE', 'BOOK_DELETE'],
    permissionsCondition: 'ANY',
    icon: 'event_note',
  },
});
