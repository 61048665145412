import MockAdapter from 'axios-mock-adapter';
import { AppMockResponseConfig } from 'app/network/app-http-request';

export function login_POST(mockAdapter: MockAdapter) {
  mockAdapter.onPost('auth/login').reply(requestConfig => {
    const response = new AppMockResponseConfig(requestConfig);
    const requestData = JSON.parse(requestConfig?.data);

    if (
      requestData.username === 'trinh178' &&
      requestData.password === '123456'
    ) {
      response.statusCode = 200;
      response.data = {
        id: 123,
        username: 'trinh178',
        firstName: 'Trinh',
        lastName: 'Pham',
        email: 'edward.pham42@gmail.com',
        token: 'token123456789',
        permissions: ['BOOK_READ', 'BOOK_CREATE', 'BOOK_UPDATE', 'BOOK_DELETE'],
      };
    } else {
      return response.replyBadRequest({
        message: 'Username or password not found',
      });
    }

    return response.reply();
  });
}

export function logout_POST(mockAdapter: MockAdapter) {
  mockAdapter.onPost('auth/logout').reply(requestConfig => {
    const response = new AppMockResponseConfig(requestConfig);
    return response.replySuccess();
  });
}
