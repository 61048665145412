import { PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initial-state';
import { appCreateSlice } from 'app/redux/tools';
import * as authLoginService from 'services/auth/login';

export default appCreateSlice({
  name: '@core',
  initialState: initialState,
  reducers: {
    setGrantedPageRouteConfigs(
      state,
      action: PayloadAction<GrantedPageRouteConfigProps[]>,
    ) {
      state.grantedPageRouteConfigs = action.payload;
    },

    loginRequest: (
      state,
      action: PayloadAction<authLoginService.RequestModel>,
    ) => {
      state.loginState = 'PENDING';
    },
    loginSuccess: (
      state,
      action: PayloadAction<authLoginService.ResponseModel>,
    ) => {
      state.loginState = 'FULFILLED';
      state.authToken = action.payload.token;
      state.userPermissions = action.payload.permissions;
      state.isAuthenticated = !!action.payload.token;
    },
    loginFailure: (state, action: PayloadAction<AppBaseError>) => {
      state.loginState = 'REJECTED';
      state.loginError = action.payload;
    },

    logoutRequest: state => {
      state.logoutState = 'PENDING';
    },
    logoutSuccess: state => {
      state.logoutState = 'FULFILLED';

      state.authToken = undefined;
      state.userPermissions = [];
      state.isAuthenticated = false;
    },
    logoutFailure: (state, action: PayloadAction<AppBaseError>) => {
      state.logoutState = 'REJECTED';
    },
  },
});
